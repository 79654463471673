@tailwind base;
@tailwind components;
@tailwind utilities;

tr.htmx-swapping td {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.clearablefileinput {
  @apply file-input w-full;
}

.paid-stamp {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: green;
  border: 2px solid green;
  transform: rotate(-15deg);
}

.overlay {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            display: none;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: rgb(0 0 0 / 50%);
  .message {
            padding: 20px;
            text-align: center;
            background: white;
            border-radius: 10px;
  }
}
